import "core-js/modules/es.array.push.js";
import { refundRulePage, refundRuleStateChange, refundRuleEdit, refundRuleOptionEdit } from "@/api/api";
export default {
  name: "insureList",
  data() {
    return {
      tableData: [],
      Info: {
        state: 1
      },
      dialogFormVisible: false,
      dialogFormVisible1: false,
      refundRuleInfo: [],
      refundRuleOptionList: [],
      pageInfo: {
        limit: 10,
        name: '',
        page: 1,
        total: 0
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.limit = 10;
      this.pageInfo.page = 1;
      this.getList();
    },
    getList() {
      refundRulePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showRenew(data) {
      this.dialogFormVisible = true;
      if (data) {
        this.Info = data;
      } else {
        this.Info = {
          state: 1
        };
      }
    },
    closeDiv() {
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    showRefundRuleOptions(data) {
      //展示细则编辑框
      this.dialogFormVisible1 = true;
      this.refundRuleInfo = data;
      if (data.refundRuleOptionList && data.refundRuleOptionList.length > 0) {
        this.refundRuleOptionList = data.refundRuleOptionList;
      } else {
        this.refundRuleOptionList = [{
          refund_rule_id: data.id,
          min_hour: '',
          max_hour: '',
          refund_ratio: ''
        }];
      }
    },
    addOption() {
      //添加细则
      let beforData = this.refundRuleOptionList[this.refundRuleOptionList.length - 1];
      if (beforData) {
        let msg = this.checkOption(beforData);
        if (msg) {
          this.$root.ElMessage.error(msg);
          return false;
        }
        let data = {
          refund_rule_id: beforData.refund_rule_id,
          min_hour: beforData.max_hour,
          max_hour: '',
          refund_ratio: ''
        };
        this.refundRuleOptionList.push(data);
      }
    },
    checkOption(data) {
      //细则校验
      if (!data) {
        return '错误数据';
      }
      if (!data.refund_rule_id) {
        return '缺少实际退款规则记录id';
      }
      if (parseInt(data.min_hour) < 0) {
        return '[最小区间]请输入大于等于0的正整数';
      }
      if (parseInt(data.max_hour) <= parseInt(data.min_hour)) {
        return '[最大区间]请输入大于最小区间值的正整数';
      }
      if (data.refund_ratio > 1 || data.refund_ratio <= 0) {
        return '退款比例必须在0~1 之间的小数或者为1';
      }
      return '';
    },
    deleteOption(index) {
      //删除规则
      if (index == 0) {
        this.$root.ElMessage.error('不能删除第一条');
        return false;
      }
      if (index > this.refundRuleOptionList.length - 1) {
        this.$root.ElMessage.error('超出控制范围');
        return false;
      }
      if (!this.refundRuleOptionList[index]) {
        this.$root.ElMessage.error('记录不存在');
        return false;
      }
      if (this.refundRuleOptionList[index + 1]) {
        this.refundRuleOptionList[index + 1].min_hour = this.refundRuleOptionList[index].min_hour;
      }
      this.refundRuleOptionList.splice(index, 1);
    },
    subOption() {
      //提交保存细则
      refundRuleOptionEdit(this.refundRuleOptionList).then(res => {
        if (res.success) {
          this.$root.ElMessage.success(res.msg);
          this.getList();
          this.closeDiv();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.limit = e;
      this.pageInfo.page = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.page = e;
      this.getList();
    },
    subData() {
      refundRuleEdit(this.Info).then(res => {
        if (res.success) {
          this.$root.ElMessage.success(res.msg);
          this.getList();
          this.closeDiv();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    changeState(id) {
      refundRuleStateChange({
        id: id
      }).then(res => {
        this.$root.msgResult(res, '', this, 'getList');
      });
    }
  }
};